h1,
h2,
h3 {
    font-weight: 800;
}

.center {
    text-align: center;
}

.fw-b {
    font-weight: 800;
}

// .title {
//     font-size: $base * 3.4;
// }

// .title-card {
//     font-size: $base * 2;
//     line-height: $base * 2.4;
// }

p {
    line-height: $base * 2.1;
}

.fs-xxs {
    font-size: $base * 1;
}

.fs-xs {
    font-size: $base * 1.5;
}

.text-20 {
    font-size: 20px;
}
.fs-s {
    font-size: 24px;
}

.fs-m {
    font-size: 18px;
    line-height: 22px;
    @include mq(laptop) {
        font-size: 24px;
        line-height: 28px;
    }
}

.fs-l {
    font-size: 22px;
    line-height: 26px;
    @include mq(laptop) {
        font-size: 34px;
        line-height: 38px;
    }
}

.fs-xl {
    font-size: $base * 3;
}

.a-l {
    text-align: left;
}

.italic {
    font-style: italic;
}
.text-wrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.leading-tight {
    line-height: 1.25;
}

.checkbox {
    background: #fff;
    border: 1px solid #000;
    display: inline-block;
    height: 1.2rem;
    width: 1.2rem;
    position: relative;
    cursor: pointer;
}
